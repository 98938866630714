export const SAVE_EMAIL = "SAVE_EMAIL";
export const READ_EMAIL = "READ_EMAIL";
export const LOGIN = "LOGIN";
export const SAVE_USER = "SAVE_USER";
export const UNAUTHORIZED = "UNAUTHORIZED";
export const AUTHORIZE = "AUTHORIZE";
export const UPDATE_USER = "UPDATE_USER";
export const USER_WALLET_INFO = "USER_WALLET_INFO";
export const UPDATE_STATS = "UPDATE_STATS";
export const SYSTEM_SETTINGS = "SYSTEM_SETTINGS";
export const GET_DEV_APPS = "GET_DEV_APPS";
export const GET_DEV_APPS_FAILED = "GET_DEV_APPS_FAILED";
export const GET_DEV_APPS_SUCCESS = "GET_DEV_APPS_SUCCESS";
export const ADD_DEV_APP = "ADD_DEV_APP";
export const UPDATE_APP = "UPDATE_APP";
export const UPDATE_APP_SUCCESS = "UPDATE_APP_SUCCESS";
export const UPDATE_APP_FAILED = "UPDATE_APP_FAILED";
export const ADD_DEV_APP_SUCCESS = "ADD_DEV_APP_SUCCESS";
export const ADD_DEV_APP_FAILED = "ADD_DEV_APP_FAILED";
export const TOGGLE_ADD_MODAL = "SHOW_ADD_MODAL";
export const TOGGLE_UPDATE_MODAL = "SHOW_UPDATE_MODAL";
export const CHANGE_APP_STATUS = "DISABLE_APP";
export const CHANGE_APP_STATUS_FAILED = "DISABLE_APP_FAILED";
export const CHANGE_APP_STATUS_SUCCESS = "DISABLE_APP_SUCCESS";