import React, { Component } from "react";
import "./static/recharge.css";
import Button, { ZOOM_IN, ZOOM_OUT } from "react-ladda";
import  { Button as CancelButton } from "react-bootstrap";
import { connect } from "react-redux";
import { get, post } from "../../../services/Transport";
import { NotificationManager } from "react-notifications";
import { PaystackButton, PaystackConsumer } from 'react-paystack';
import bigjs from 'big.js'
var Big = require('big.js')

const statusTypes = Object.freeze({
  successful: 'success',
  declined: ''

})

class Recharge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timer: 5,
      loading: false,
      invoice_success: false,
      recharge_success: false,
      recharge_failed: true,
      user_amount: 0.0,
      tranx_fee: 0.03,
      payment_type: "online",
      payment_type_clicked: false,
      default_sms_rate: this.props.settings
        ? parseFloat(this.props.settings.default_sms_rate)
        : 0.03, //replace with data from api
      gateway_transaction_fee: this.props.settings
        ? parseFloat(this.props.settings.gateway_transaction_fee)
        : 0.02, //replace with data from api
      url: "",
      side: true,
      amount_paid: 0.0,
      email: props.user.user_email,
      invoice_id: '',
      validateNumber:false

    }
    this.componentProps = {
      ref: '',
      reference: '',
      email: props.user.user_email,
      amount: 0.00,
      //publicKey: 'pk_test_55af3ddd45defb7a2236e2b16efe5c4ede8f793e',
      publicKey: 'pk_live_ddad106b03691acc5ba41f861200e5c4a1f3f5f3',
      text: 'Pay Now',
      onSuccess: (reference) => this.handlePaystackSuccessAction(reference),
      onClose: (closed) => this.handlePaystackCloseAction(closed),
      currency: "GHS"
    }

  }

  handlePaystackSuccessAction = async (reference) => {
    // Implementation for whatever you want to do with reference and after success call.
    const { history } = this.props
    const { invoice_id } = this.state
    //
    if (reference.status == statusTypes.successful) {
      const response = await get(`/billing/invoices/status?invoice_id=${invoice_id}`)

      const { status } = response.data.data
      if (status == "PAID") {
        NotificationManager.success("Payment Confirmation Successful.");

        history.push(
          { pathname: `/v1/billing/recharge/history` }
        )
      }
    }



    else {
      NotificationManager.error("Payment failed, please try again.")

    }
  };

  // you can call this function anything
  handlePaystackCloseAction = (closed) => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    NotificationManager.error("You have cancelled the payment.")
    window.location.reload();
  }

  componentDidMount() {
    document.title = "Recharge your account - Wittyflow";
  }

  handleAmountInput = (event) => {
    event.preventDefault();

    const { value } = event.target;

    this.setState({ user_amount: value }, () => {
      //converted to pesewas because of paystack requirements
      let numberRegex = new RegExp(/^\d*\.?\d*$/)
      let isNumberValidated = numberRegex.test(this.state.user_amount)
      if(isNumberValidated == false){
        this.setState({validateNumber:false})
       // NotificationManager.error("Please enter a valid top up amount");

      }
      else{
        let usernum = Number(this.state.user_amount).toFixed(2)
        let userAmount = new Big(usernum)

        let gatewayfee = Number(this.state.gateway_transaction_fee).toFixed(2)
        let gateway_fee =  new Big(gatewayfee)
         let fee = userAmount.times(gateway_fee)
         let total_cost = userAmount.add(fee).times(100)
         let finalAmout =Number(total_cost.valueOf())
         this.componentProps.amount = finalAmout
      }
     
    });
  };

  goBack = () => {
    this.componentProps.reference = ''

    this.setState({
      invoice_success: false,
      loading: false,
    });
  }

  handlePayment2 = (e) => {
    const { user_amount, payment_type, payment_type_clicked } = this.state;
    this.setState({ loading: true });
    const { min_topup_amount, max_topup_amount } = this.props.settings

    let userAmountFloat = parseFloat(user_amount)
    if (!userAmountFloat) {
      NotificationManager.warning(
        `Please provide amount in figures`
      );
      this.setState({ loading: false });
      return;
    }

    else if (userAmountFloat < parseFloat(min_topup_amount)) {
      NotificationManager.warning(
        `Please provide amount not less than GHS ${min_topup_amount}`
      );
      this.setState({ loading: false });
      return;
    }
    else if (userAmountFloat > parseFloat(max_topup_amount)) {
      NotificationManager.warning(
        `Please provide amount not more than GHS ${max_topup_amount}`
      );
      this.setState({ loading: false });
      return;
    }
    // alert(this.state.payment_type);
    post("/billing/invoices/create", {
      amount: userAmountFloat,
      channel: payment_type,
    })
      .then((res) => {
        if (res.data.status === "success") {
          this.componentProps.reference = res.data.data.invoice_id

          this.setState({
            invoice_id: res.data.data.invoice_id,
            invoice_success: true,
            loading: false,
          });
        } else {
          this.setState({ loading: false, side: true });
          NotificationManager.error(res.data.message);
        }
      })
      .catch((err) => {
        if (err.response) {
          NotificationManager.error(err.response.data.message);
        }
        this.setState({ loading: false, side: true });
      });
  };

  handleChange = event => {
    event.preventDefault();
    const { name, value } = event.target;

    this.setState({ [name]: value });
  };



  handlePaymentType = (e) => {
    // alert(e.target.value);
    this.setState({
      payment_type_clicked: true,
      payment_type: e.target.value,
    });
  };

  render() {
    let sms_credits = Math.ceil(
      this.state.user_amount / this.state.default_sms_rate
    );
    let fee = this.state.user_amount * this.state.gateway_transaction_fee; //remeber to replace the 0.03 with the data from system settings
    let tranx_fee = fee.toFixed(2);
    let total_cost = parseFloat(this.state.user_amount) + parseFloat(tranx_fee);
    return (
      <>
        <div className="row justify-content-center">
          <div className="col-lg-8">
            {/*begin::Portlet*/}
            {!this.state.invoice_success && this.componentProps.reference == '' && (
              <div className="kt-portlet kt-portlet--mobile">
                <div className="kt-portlet__head">
                  <div className="kt-portlet__head-label">
                    {this.props.settings && (
                      <h3 style={{ textAlign: 'center' }} className="kt-portlet__head-title text-center">
                        Add funds to your account{" "}

                      </h3>
                    )}
                  </div>
                </div>
                <div className="kt-portlet__body">
                  <div className="amount-box">
                    <div className="how-much-box  mb-3">
                      <h4 className="how-much-header">
                        <span>How much balance would you like to add?</span>
                      </h4>

                    </div>

                    <span className="type-amount-span mb-3">
                      <span>Type amount below:</span>
                    </span>
                    <div className="input-group mb-3mt-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">
                          GHS
                        </span>
                      </div>

                      <input
                        type="text"
                        className="form-control"
                        placeholder="Amount"
                        name="amount"
                        onChange={this.handleAmountInput}
                        value={this.state.user_amount}
                      />
                    </div>

                    <Button
                      loading={this.state.loading}
                      data-color="blue"
                      data-style={ZOOM_OUT}
                      data-spinner-size={20}
                      data-spinner-color="#ffffff"
                      data-spinner-lines={12}
                      className="ladda-button-primary btn-flat mt-2 btn-sm"
                      onClick={this.handlePayment2}
                    >
                      Continue
                    </Button>
                  </div>
                </div>
              </div>
            )}

            {/*end::Portlet*/}
          </div>

          <div className="col-lg-6">

            {this.state.invoice_success && this.componentProps.reference !== '' && (
              <div className="kt-portlet kt-portlet--mobile">
                <div className="kt-portlet__head">
                  <div className="kt-portlet__head-label">
                    <h3 className="kt-portlet__head-title text-center">Order Summary</h3>
                  </div>
                </div>
                <div className="kt-portlet__body">
                  <div className="order-content-box">
                    <div className="order-content-detail">
                      <div className="order-amount-box">
                        <div className="order-amount-content">
                          <span className="order-amount-key">
                            <span>Email</span>:
                          </span>
                          <h4 className="order-amount-value">
                            {this.state.email}
                          </h4>
                        </div>
                        <div className="order-amount-content">
                          <span className="order-amount-key">
                            <span>Order Amount</span>:
                          </span>
                          <h4 className="order-amount-value">
                            GHS&nbsp; {this.state.user_amount}{" "}
                          </h4>
                        </div>

                        <div className="sms-credit-content mt-1">
                          <div className="sms-credit-key-box">
                            <span className="sms-credit-key">
                              <span>Transaction Fee</span>:
                            </span>
                          </div>
                          <h4 className="sms-credit-value">
                            {" "}
                            GHS&nbsp; {tranx_fee}{" "}
                          </h4>
                        </div>

                        <div className="sms-credit-content mt-1">
                          <div className="sms-credit-key-box">
                            <span className="sms-credit-key">
                              <span>You will receive</span>:
                            </span>
                          </div>
                          <h4 className="sms-credit-value"> {sms_credits} SMS Credits</h4>
                        </div>
                      </div>

                      <div className="total-amount-box">
                        <div className="css-1p0ipqa-StyledFlex e1u98m330">
                          <h4 className="total-amount-key">
                            <span>Total</span>
                          </h4>
                        </div>
                        <h2 className="total-amount-value">
                          GHS&nbsp;{total_cost.toFixed(4)}
                        </h2>
                      </div>
                      <div className="new-balance-box">
                        <div className="css-1p0ipqa-StyledFlex e1u98m330">
                          <p className="new-balance-key">
                            <span>New balance after payment</span>
                          </p>
                        </div>
                        {this.props.user && (
                          <span className="new-balance-value">
                            GHS&nbsp;
                            {this.state.user_amount
                              ? (
                                parseFloat(this.props.user.balance) +
                                parseFloat(this.state.user_amount)
                              ).toFixed(2)
                              : this.props.user.balance}
                          </span>
                        )}
                      </div>
                      <PaystackButton className="ladda-button-primary btn-flat" {...this.componentProps} />
                     
                      <CancelButton className="mt-2" onClick={this.goBack} variant="dark"> Go back</CancelButton>

                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.auth,
  };
};

export default connect(mapStateToProps)(Recharge);
